<template>
  <base-card class="update-company-info">
    <v-card-text class="pt-10">
      <v-form
        ref="form"
        v-model="validForm"
        lazy-validation
      >
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3" offset-md="2" offset-lg="3" class="py-0">
            <v-text-field
              v-model="userFirstName"
              :label="$t('common.firstName')"
              :rules="requiredRules"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <v-text-field
              v-model="userLastName"
              :label="$t('common.lastName')"
              :rules="requiredRules"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="4" lg="3" offset-md="2" offset-lg="3" class="py-0">
            <v-text-field
              v-model="userEmail"
              :label="$t('common.email')"
              :rules="emailRules"
            />
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" class="py-0">
            <v-text-field
              v-model="userConfirmEmail"
              :label="$t('common.confirmEmail')"
              :rules="emailConfirmRules"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3" class="py-0">
            <v-text-field
              v-model="streetAddress"
              :label="$t('address.address')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="6" md="4" lg="2" offset-md="2" offset-lg="3" class="py-0">
            <v-text-field
              v-model="city"
              :label="$t('address.city')"
            />
          </v-col>

          <v-col cols="12" sm="3" md="2" lg="2" class="py-0">
            <v-text-field
              v-model="country"
              :label="$t('address.country')"
            />
          </v-col>

          <v-col cols="12" sm="3" md="2" lg="2" class="py-0">
            <v-text-field
              v-model="postalCode"
              :label="$t('address.postalCode')"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8" lg="6" offset-md="2" offset-lg="3" class="py-0">
            <v-textarea
              v-model="aboutMe"
              :label="$t('user.aboutMe')"
              row-height="20"
              auto-grow
              hide-details
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions class="justify-center mt-2 pb-4">
      <v-btn
        class="primary px-10 mr-4"
        :disabled="!validForm"
        @click="updateProfile"
      >
        {{ $t("user.updateProfile") }}
      </v-btn>
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  export default {
    metaInfo: {
      title: 'Profile',
    },

    data () {
      return {
        userFirstName: '',
        userLastName: '',
        userEmail: '',
        userConfirmEmail: '',
        streetAddress: '',
        city: '',
        postalCode: '',
        country: '',
        aboutMe: '',
        requiredRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
        emailRules: [
          (v) => !!v || this.$t('error.requiredField'),
          (v) => !v || /.+@.+\..+/.test(v) || this.$t('error.invalidEmail'),
        ],
        emailConfirmRules: [
          (v) => v === this.userEmail || this.$t('error.confirmEmailNotMatch'),
        ],
        validForm: true,
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
      }),
    },

    watch: {
      currentUser () {
        this.initForm()
      },
    },

    mounted () {
      this.initForm()
    },

    methods: {
      ...mapActions({
        updateUserProfile: 'updateUserProfile',
        setLoading: 'setLoading',
        setLoadingText: 'setLoadingText',
      }),

      initForm () {
        this.userFirstName = this.currentUser.firstName || ''
        this.userLastName = this.currentUser.lastName || ''
        this.userEmail = this.currentUser.email || ''
        this.streetAddress = this.currentUser.streetAddress || ''
        this.city = this.currentUser.city || ''
        this.country = this.currentUser.country || ''
        this.postalCode = this.currentUser.postalCode || ''
        this.aboutMe = this.currentUser.aboutMe || ''
      },

      async updateProfile () {
        const isValidForm = this.$refs.form.validate()

        if (!isValidForm) {
          return
        }

        this.setLoading(true)
        this.setLoadingText(this.$t('user.updatingProfile'))
        try {
          await this.updateUserProfile({
            email: this.userEmail,
            firstName: this.userFirstName,
            lastName: this.userLastName,
            streetAddress: this.streetAddress,
            city: this.city,
            country: this.country,
            postalCode: this.postalCode,
            aboutMe: this.aboutMe,
          })

          showSuccessDialog(this.$t('user.updateProfileSuccessfully'))
        } catch (error) {
          const errorMessage = util.getErrorResponse(error)
          showErrorDialog(errorMessage)
        }
        this.setLoading(false)
      },
    },
  }
</script>
